<template>
  <v-card>
    <v-toolbar dark color="primary" dense class="mb-5">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Subcategoria Compliance</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row justify="start" no-gutters class="pa-4 mt-8">
        <v-col class="d-flex pr-5" lg="6" md="12" sm="12">
          <v-text-field
            v-model="subcategory.name"
            outlined
            dense
            label="Nome da Subcategoria *"
            :rules="$rules.required"
            required />
        </v-col>

        <v-col class="d-flex pr-5" lg="6" md="12" sm="12">
          <v-select
            v-model="subcategory.categoryId"
            :items="complianceCategory"
            :rules="$rules.required"
            outlined
            dense
            required
            item-text="title"
            item-value="id"
            label="Categoria *"
            class="form-control" />
        </v-col>

        <v-row justify="center" align="center">
          <v-col class="d-flex pr-5 mt-4" lg="12" md="8" sm="12">
            <v-file-input
              v-model="imagesUpload"
              outlined
              label="Clique para adicionar imagens do tamanho 1024x768"
              prepend-icon="mdi-camera"
              required
              accept="image/jpeg, image/png"
              @change="onFilesSelected" />
          </v-col>
          <v-col class="pb-10">
            <gallery
              :images="imagesPreview"
              :rules="$rules.required"
              required
              @image-deleted="onDeleteImage" />
          </v-col>
        </v-row>
        <v-dialog v-model="dialogCropImage" persistent>
          <v-card>
            <div>
              <v-card-title class="text-h5">
                Recorte da imagem para tamanho 1024x768.
              </v-card-title>
            </div>
            <div>
              <v-card-text>
                Para melhor visualização da imagem no Informa Site, recomendamos
                utilizar o tamanho da imagem em 1024x768. <br />
                Utilize o "scroll" do mouse para ajustar a imagem.
                <br />
                <br />
                Clique e segure na imagem para arrastar e posicionar.
              </v-card-text>
            </div>
            <div style="padding-left: 600px">
              <resize-image
                ref="image"
                :target-width="resizeImageParams.targetWidth"
                :target-height="resizeImageParams.targetHeight"
                :min-aspect-ratio-value1="
                  resizeImageParams.minAspectRatioValue1
                "
                :min-aspect-ratio-value2="
                  resizeImageParams.minAspectRatioValue2
                "
                :max-aspect-ratio-value1="
                  resizeImageParams.maxAspectRatioValue1
                "
                :max-aspect-ratio-value2="
                  resizeImageParams.maxAspectRatioValue2
                "
                :image-param="imagesPreview" />
            </div>
            <v-card-actions>
              <v-btn color="red darken-1" text @click="cancelCropImage()">
                Cancelar
              </v-btn>
              <v-btn color="green darken-1" text @click="cropImageOnConfirm">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
    <v-row class="mt-10" justify="center" no-gutters>
      <v-card-actions>
        <v-btn color="secondary" @click="close"> Cancelar </v-btn>
        <v-btn
          v-if="loading === true"
          color="primary"
          loading
          @click="submit" />
        <v-btn v-else color="primary" @click="submit"> Salvar </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import sanitize from 'sanitize-filename';
import { createFeaturedPhoto } from '@/services/photos-service';
import ResizeImage from '../../../components/shared/ResizeImage.vue';
import imageFormater from '../../../utils/convert-image-to-file';
import ComplianceSubcategoryService from '../../../services/compliance-subcategory.service';
import ComplianceCategoryService from '../../../services/compliance-category.service';

export default {
  name: 'SubcategoryForm',

  components: {
    ResizeImage,
  },

  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      subcategory: {},
      linkFiles: [],
      dialogCropImage: false,
      complianceCategory: [],
      imagesPreview: [],
      imagesUpload: null,
      updateButton: false,
      loading: false,
      resizeImageParams: {
        targetWidth: 1024,
        targetHeight: 768,
        minAspectRatioValue1: 4,
        minAspectRatioValue2: 3,
        maxAspectRatioValue1: 4,
        maxAspectRatioValue2: 3,
      },
      attcUpload: [],
      attcFile: [],
      modalConfirm: false,
    };
  },
  async created() {
    if (this.populateWith.id) {
      const { image, category } = this.populateWith;
      this.imagesPreview = image ? [image] : [];
      this.subcategory = { ...this.populateWith };
      this.subcategory.categoryId = category.id;
      this.updateButton = true;
    }
    this.loadComplianceCategories();
  },
  methods: {
    async loadComplianceCategories() {
      this.complianceCategory = await ComplianceCategoryService.getCategory();
    },
    close() {
      this.$emit('close-dialog');
    },

    onFilesSelected(files) {
      if (files) {
        this.dialogCropImage = true;
        this.imagesPreview = [URL.createObjectURL(files)];
      }
    },

    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      this.imagesUpload = null;

      this.subcategory = { ...this.subcategory, image: null };
    },

    async checkImagesUploadAndCreatePhoto() {
      if (this.imagesUpload) {
        const imageType = imageFormater.getMimeType(this.imagesPreview);
        const formattedTitle = sanitize(this.subcategory.name);
        const imageFormatted = imageFormater.convertImageToFile(
          this.imagesPreview,
          `${formattedTitle.substring(0, 10)}.${imageType}`,
        );
        const photo = await createFeaturedPhoto(imageFormatted);
        this.subcategory = { ...this.subcategory, image: photo.link };
      }
    },

    cropImageOnConfirm() {
      const { coordinates, canvas } = this.$refs.image.$refs.cropper.getResult();
      this.coordinates = coordinates;
      const imageToArray = canvas.toDataURL();
      this.imagesPreview = [imageToArray];
      this.dialogCropImage = false;
    },

    cancelCropImage() {
      this.dialogCropImage = false;
      this.imagesUpload = null;
      this.imagesPreview = [];
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        if (this.populateWith.id) {
          await ComplianceSubcategoryService.updateSubcategory({
            ...this.subcategory,
          });
          this.$emit('subcategory-edited', { ...this.subcategory });
          this.$toast.success('Subcategoria editada com sucesso');
        } else {
          await ComplianceSubcategoryService.createSubcategory({
            ...this.subcategory,
          });
          this.$emit('subcategory-added', { ...this.subcategory });
          this.$toast.success('Subcategoria cadastrada com sucesso');
          this.clearForm();
        }
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.$refs.form.reset();
      this.subcategory.name = null;
      this.imagesPreview = [];
      this.$refs.form.resetValidation();
    },
  },
};
</script>
